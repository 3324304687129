import React, { useEffect } from 'react'

import { useClearOrderStorage } from '../../model/store'
import { Button2 } from '../../../../../components/button2'
import { SuccessStatusIcon } from '../../components/icons'
import { ThankYouTemplate } from '../../components/templates/thank-you-template'

export const ThankYouOrderReceivedPage = () => {
  const { clearOrderStorage } = useClearOrderStorage()
  const getConversationData = JSON.parse(localStorage.getItem('conversation'))

  useEffect(() => {
    clearOrderStorage && clearOrderStorage()
  }, [])

  const handleClose = () => {
    window.location.href = '/#calculator'
  }
  const getFormName = localStorage.getItem('FormName')
  const parsedData = JSON.parse(localStorage.getItem('Calculation-data'))

  const parsedOnlineData = JSON.parse(localStorage.getItem('order-info'))
  const isWeekLength6 =
    parsedOnlineData && parsedOnlineData.isOrderFoodWeekMeal5DaysLength
      ? '5'
      : '6'
  const isPaymentOnline =
    parsedOnlineData && parsedOnlineData.onlinePayment
      ? 'online-card'
      : 'faktura'
  const deliveryTimeRange =
    parsedOnlineData &&
    parsedOnlineData.deliveryTime &&
    `${parsedOnlineData.deliveryTime[0]}-${parsedOnlineData.deliveryTime[1]}`

  const pushDataLayerEvent = formName => {
    let event
    let pageURL
    let variables = {}

    switch (formName) {
      case 'vege-objednavka':
        event = 'conversion_vegetarian_menu_order'
        pageURL = '/thank-you-order-received'
        break
      case 'vege-objednavka_en':
        event = 'conversion_vegetarian_menu_order'
        pageURL = '/thank-you-order-received'
        break
      case 'konzultace-objednavka':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-order-received'
        break
      case 'hromadna-objednavka':
        event = 'conversion_multi_order'
        pageURL = '/thank-you-order-received'
        break
      case 'hromadna-objednavka_en':
        event = 'conversion_multi_order'
        pageURL = '/thank-you-order-received'
        break
      case 'konzultace-dietolog':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-order-received'
        break
      case 'konzultace-dietolog_en':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/thank-you-order-received'
        break
      case 'darkovy-certifikat':
        event = 'conversion_gift_voucher'
        pageURL = '/thank-you-order-received'
        break
      case 'conversion-callback':
        event = 'conversion_callback'
        pageURL = '/thank-you-order-received'
        break
      case 'demo-objednavka':
        event = 'conversion_demo_order'
        pageURL = '/thank-you-order-received'
        break
      case 'demo-objednavka_en':
        event = 'conversion_demo_order'
        pageURL = '/thank-you-order-received'
        break
      case 'order-call':
        event = 'conversion_callback'
        pageURL = `/thank-you-order-received/price_daily=${getConversationData.price}&&program=${getConversationData.program}&&program_length=${getConversationData.lenght}&&meal_count
=${getConversationData.numberOfMeals}&&person_count=${getConversationData.person}&&testovani=${getConversationData.testovani}&&coupon=${getConversationData.promo}&&kcal_count=${getConversationData.kalorie}&&menu_choice=${getConversationData.menu}&&sex_choice=${getConversationData.sex}&&week_length=${getConversationData.weekLenght}`
        variables = {
          price_daily: getConversationData.price_daily,
          program: getConversationData.program,
          program_length: getConversationData.lenght,
          meal_count: getConversationData.numberOfMeals,
          person_count: getConversationData.person,
          testovani: getConversationData.testovani,
          coupon: getConversationData.promo,
          kcal_count: getConversationData.kalorie,
          menu_choice: getConversationData.menu,
          sex_choice: getConversationData.sex,
          week_length: getConversationData.weekLenght,
        }
        break
      case 'kalkulacka':
        event = 'conversion_calculator'
        pageURL = `/thank-you-order-received/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
        =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
        variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'kalkulacka_en':
        event = 'conversion_calculator'
        pageURL = `/thank-you-order-received/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
=${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
        variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'online-order':
        window.dataLayer = window.dataLayer || []
        window.dataLayer &&
          window.dataLayer.push({
            event: 'conversion_online_order',
            pageURL: `/thank-you/price_daily=${parsedOnlineData.price}&&program=${parsedOnlineData.Program}&&program_length=${parsedOnlineData.length}&&meal_count=${parsedOnlineData.numberOfMeals}&&person_count=${parsedOnlineData.NumberOfPeople}&&testovani
                      =${parsedOnlineData.testovani}&&coupon=${parsedOnlineData.promo}&&kcal_count=${parsedOnlineData.kcal}&&menu_choice=${parsedOnlineData.CustomerMenu}&&sex_choice=${parsedOnlineData.CustomerGender}&&week_length=${isWeekLength6}&&company_order=${parsedOnlineData.isCompanyOrder}&&city=${parsedOnlineData.city}&&payment_method=${isPaymentOnline}&&delivery_time_range=${deliveryTimeRange}&&price=${parsedOnlineData.price}&&discount=${parsedOnlineData.PriceDiscount}`,
            price_daily: parsedOnlineData.price_daily,
            program: parsedOnlineData.Program,
            program_length: parsedOnlineData.length,
            meal_count: parsedOnlineData.numberOfMeals,
            person_count: parsedOnlineData.NumberOfPeople,
            testovani: parsedOnlineData.testovani,
            coupon: parsedOnlineData.promo,
            kcal_count: parsedOnlineData.kcal,
            menu_choice: parsedOnlineData.CustomerMenu,
            sex_choice: parsedOnlineData.CustomerGender,
            week_length: isWeekLength6,
            company_order: parsedOnlineData.isCompanyOrder,
            city: parsedOnlineData.city,
            payment_method: isPaymentOnline,
            delivery_time_range: deliveryTimeRange,
            price: parsedOnlineData.price,
            discount: parsedOnlineData.PriceDiscount,
          })
        break
      default:
        break
    }

    if (event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: event,
          pageURL: pageURL,
          ...variables,
        })
    }
  }

  useEffect(() => {
    pushDataLayerEvent(getFormName)
  }, [getFormName])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer &&
      window.dataLayer.push({
        event: 'purchase',
        pageURL: window.location.pathname,
      })
  }, [])

  return (
    <ThankYouTemplate onClose={handleClose}>
      <header>
        <SuccessStatusIcon />
        <h1>Děkujeme, vaši objednávku jsme úspěšně přijali.</h1>
      </header>
      <p>
        Mezitím jsme vám do e-mailu poslali veškeré informace k dokončení platby
        bankovním převodem.
      </p>
      <Button2 color="secondary" onClick={handleClose}>
        Zpět na hlavní stránku
      </Button2>
    </ThankYouTemplate>
  )
}
